import styled from '@emotion/styled'
import { uniqBy } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { Link } from 'react-scroll'

import { Props as RoomProps } from './Item'

export interface Props {
  rooms: RoomProps[]
}

export const Filters = memo(function Filters({ rooms }: Props) {
  if (rooms.length < 1) {
    return null
  }

  const [filtersFixed, setFiltersFixed] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setFiltersFixed(window.scrollY > window.innerHeight)
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Container className={filtersFixed ? 'fixed' : undefined}>
      {uniqBy(rooms, 'title').map((item, index) => {
        const section = item.title
          ? item.title.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()
          : undefined

        return (
          <Filter
            activeClass="active"
            key={index}
            offset={-50}
            spy={true}
            smooth={true}
            to={`room-${section}`}
          >
            {`${item.title}`}
          </Filter>
        )
      })}
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 1.87vw;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Filter = styled(Link)`
  position: relative;
  padding: 0 1.87vw;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.93vw;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;

  &:not(:last-of-type) {
    &:after {
      @media (min-width: 1200px) {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 0.0625rem;
        height: 1.87vw;
        background-color: ${({ theme }) => theme.colors.variants.primaryDark};
      }
    }
  }

  @media (max-width: 1199px) {
    font-size: 0.6875rem;
  }

  &.active {
    opacity: 0.6;
    pointer-events: none;
  }

  &:hover {
    opacity: 0.6;
  }
`
